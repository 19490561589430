<template>
    <div class="app" v-if="ready">
  
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet" />
        <div class="pseudoCatSubCat" v-if="config.Settings.displayMode==1">
            <div class="p-5">
                <h2 class="title-info">{{ this.config.Settings.title }}</h2>
                <p class="heading2">{{ replaceInfo( this.config.Settings.info )}}</p>
                <div class="parentId mt-5" :id="'parentId'+config.SortOrder">
                    <vue-masonry-wall :items="Object.entries(checkbox2)" :options="{ width: 400, padding: 15 }" @input="widthChange">
                            <template v-slot:default="{ item }">
                                <span class="heading">{{ item[0].replaceAll('|',',') }}</span>
                                
                                <div  v-for="(subItem, j) in item[1]" :key="j">
                                    <div class="checkbox" v-if="(j+1)%3==0">
                                        <span class="checkrow" >
                                        <span class="inputSymbol"><input :id="item[1][0] + j+config.SortOrder" type="checkbox" @click="maxCount2($event)" /></span>
                                        <span class="inputTitle ml-2">{{ subItem.replaceAll('|',',') }}</span>
                                      
                                    </span>

                                    </div>
                                   
                                    
                                </div>
                            </template>
                        </vue-masonry-wall>
                    <!-- <vue-masonry-wall :items="Object.entries(checkbox2)" :options="{ width: 400, padding: 15 }">
                        <template v-slot:default="{ item }">
                            <span class="heading">{{ item[0].replaceAll('|',',') }}</span>
                            <div class="checkbox" v-for="(subItem, j) in item[1]" :key="j">
                                <span class="checkrow">
                                    <span class="inputSymbol"><input :id="item[0] + j" type="checkbox" @click="maxCount2($event)" /></span>
                                    <span class="inputTitle ml-2">{{ subItem.replaceAll('|',',') }}</span>
                                </span>
                            </div>
                        </template>
                    </vue-masonry-wall> -->
                    <div v-if="isSuccess" class="alert alert-success" role="alert">Successfully saved!</div>
                    <div v-if="isSuccessToast" class="alert_toast alert alert-success" role="alert">{{toastMessage}}</div>
                    <div v-if="isFlag" class="alert_toast alert alert-danger" role="alert">{{toastMessage}}</div>
                    <div class="text-right">
                        <button type="button" class="TextButton" @click="reloadpage">Cancel</button>
                        <button type="button" class="TextButton PrimaryButton ml-3" @click="saveData">Save</button>
                    </div>
                    <div class="container" v-if="isFlag || isSuccessToast">
                        <div class="row">
                            <div class=" col-sm-6 col-md-6">
                                <div class="alert_style alert " :class="isSuccessToast ? 'alert-success': 'alert-danger'">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true" @click="isFlag=false,isSuccessToast=false">×</button>
                                    <span class="glyphicon glyphicon-ok"></span>
                                    <strong>{{isSuccessToast ? 'Selection Applied': 'Warning Message'}}</strong>
                                    <hr class="message-inner-separator">
                                    <p>{{toastMessage}}</p>
                                    <p>{{toastMessage2}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div v-if="config.Settings.displayMode==0">
            <h2 class="title-info">{{ this.config.Settings.title }}</h2>
                    <p class="heading2">{{ replaceInfo( this.config.Settings.info )}}</p>
            <p class="selectList">
                <strong>Your current selection: </strong>
                <a href="#" class="edit_btn"  @click.prevent="show()">                    
                    Edit your selection
                </a> 


                 
                 




                        





                <em v-if="checktempID2 === '' ||checktempID2.length ==0">None currently selected</em>
            </p>
            <ul  v-if="(checktempID2 !== '' && checktempID2.length >0) && config.Settings.displayStyle=='0' && MainList" class="categorytagscomp">
                   
                    <!-- <li v-for="(item, j) in checktempID2" :key="j">{{item ? item.replaceAll('|',',') :''}}</li> -->
                    <li class="blockrow" v-for="(item,key, j) in (MainList)" :key="j">
                        <span class="parentCategory">{{key}}</span>
                        <span v-for="(item, j) in item" :key="j" class="subCategory">{{item ? item.replaceAll('|',',') :''}}</span>
                    </li>
                    
                    
                    
                </ul> 

            <vue-masonry-wall v-if="(checktempID2 !== '' && checktempID2.length >0) && config.Settings.displayStyle=='1' && isMainList" :items="Object.entries(MainList)" :options="{ width: 400, padding: 15 }" @input="widthChange">
                <template v-slot:default="{ item }">
                    
                    <span class="heading"> {{ item && item[0].replaceAll('|',',')}}</span>
                    
                    <div  v-for="(subItem, j) in item[1]" :key="j">
                        <div class="checkbox" >
                            <span class="checkrow" >
                            
                            <span class="inputTitle ml-2">{{ subItem ? subItem.replaceAll('|',',') :''}}</span>
                        </span>

                        </div>
                        
                        
                    </div>
                </template>
            </vue-masonry-wall>


            
        </div>
        <modal
        v-if="config.Settings.displayMode==0"
        :name="'practiceAreasDialog'+config.SortOrder"           
        :clickToClose="false" 
        width="80%"
        height="80%"
        classes="modalDialog"      
      >
        <div class="modalHeader">
            <button type="button"  @click.prevent="$modal.hide('practiceAreasDialog'+config.SortOrder)" class="TextButton" ><i class="fa fa-close close_btn" ></i></button>
            
        </div>
        <div class="modalBody">        
            <div class="pseudoCatSubCat">
                <div class="p-5">
                    <h2 class="title-info">{{ this.config.Settings.title }}</h2>
                    <p class="heading2">{{ replaceInfo( this.config.Settings.info )}}</p>
                    <div class="parentId mt-5" :id="'parentId'+config.SortOrder">
                        <vue-masonry-wall :items="Object.entries(checkbox2)" :options="{ width: 400, padding: 15 }" @input="widthChange">
                            <template v-slot:default="{ item }">
                                <span class="heading">{{ item[0].replaceAll('|',',') }}</span>
                                
                                <div  v-for="(subItem, j) in item[1]" :key="j">
                                    <div class="checkbox" v-if="(j+1)%3==0">
                                        <span class="checkrow" >
                                        <span class="inputSymbol"><input :id="item[1][0] + j+config.SortOrder" type="checkbox" @click="maxCount2($event)" /></span>
                                        <span class="inputTitle ml-2">{{ subItem.replaceAll('|',',') }}</span>
                                    </span>

                                    </div>
                                   
                                    
                                </div>
                            </template>
                        </vue-masonry-wall>
                        <!-- <div v-if="isSuccess" class="alert alert-success" role="alert">Successfully saved!</div> -->
                        <div v-if="isSuccessToast" class="alert_toast alert alert-success" role="alert">{{toastMessage}}</div>
                        <div v-if="isFlag" class="alert_toast alert alert-danger" role="alert">{{toastMessage}}</div>
                        
                        <div class="container" v-if="isFlag || isSuccessToast">
                        
                            <div class="row">
                                <div class=" col-sm-12">
                                    <div class=" alert alert_style " :class="isSuccessToast ? 'alert-success': 'alert-danger'">
                                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true" @click="isFlag=false,isSuccessToast=false">×</button>
                                        <span class="glyphicon glyphicon-ok"></span>
                                        <strong>{{isSuccessToast ? 'Selection Applied': 'Warning Message'}}</strong>
                                        <hr class="message-inner-separator">
                                        <p>{{toastMessage}}</p>
                                        <p>{{toastMessage2}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        
        </div>
        <div class="modalFooter pseudoCatSubCat">
            <div class="row">
                <div class="col-sm-8">
                    <div v-if="isSuccess" class="alert alert-success" role="alert">Successfully saved!</div>
                </div>
                <div class="col-sm-4">
                    <div class="footer-btn">
                        <!-- <div class="row">
                            <div class="col-sm-6"> -->
                                <button type="button" class="TextButton actionBtn" @click="reloadpage">Cancel</button>
                            <!-- </div>
                            <div class="col-sm-6"> -->
                                <button type="button"  class="TextButton PrimaryButton actionBtn ml-3" @click="saveData">Save and Close </button>
                            <!-- </div>

                        </div> -->
                        
                        
                        
                    </div>
                </div>
            </div>
           
            
        </div>
      </modal>
    </div>
</template>


<script>
import Vue from "vue"
import VModal from 'vue-js-modal'
Vue.use(VModal)
import mtapi from "./mtapi";
import VueMasonryWall from "vue-masonry-wall";

import { ImisCheckBoxList, ImisCheckBox } from "./components/ui";

export default {
    name: "display",
    props: ["ContentKey", "ContentItemKey"],
    components: { VueMasonryWall, ImisCheckBoxList, ImisCheckBox, },
    data() {
        return {
            isMainList: false,
            MainList: {},
            checktempID2: [],
            checktempID:[],
            practiceAreaList:'',
            subCategoryArr: [],
            categoryArr: [],
            varMyTimeout: clearTimeout(),
            maxSubCat: 0,
            maxCategory: 0,
            maxCounter: 0,
            maxCounter2: 0,
            apiPanelCat: '',
            apiPanelSubCat: '',
            responseData: {},
            isSuccess: false,
            ready: false,
            config: {
                Settings: {},
            },
            item: [],
            checkbox: {},
            checkbox2: {},
            selectId: "",
            isFlag: false,
            isSuccessToast: false,
            toastMessage: '',
            toastMessage2: ''
        };
    },
    async created() {
        this.apiPanelCat = this.config.Settings.apiPanelCat
        this.apiPanelSubCat = this.config.Settings.apiPanelSubCat
        // load configuration for iPart
        let response = await mtapi.axios.get(`/api/ContentItem?ContentKey=${this.ContentKey}&ContentItemKey=${this.ContentItemKey}`);
        if (response?.data?.$type?.startsWith(`Asi.Soa.Core.DataContracts.PagedResult`))
            this.config = response?.data?.Items?.$values[0]?.Data;
        else this.config = response?.data;
        // once all data loaded ok to mount the component
      
      
        let checkBoxLists = await mtapi.axios.get(`/api/iqa?QueryDocumentVersionKey=${this.config.Settings.catSubCat}&Limit=500`);
        let queryResult = new mtapi.ImisEntityCollection(checkBoxLists?.data);
    
        let resp = queryResult.Entities
        resp.forEach((item, i) => {
            if (this.checkbox.hasOwnProperty(item[this.config.Settings.codeCat])) {
                this.checkbox[item[this.config.Settings.codeCat]].push(item[this.config.Settings.codeSubCat])
                 this.checkbox2[item[this.config.Settings.descCat]].push(item[this.config.Settings.codeCat],item[this.config.Settings.codeSubCat],item[this.config.Settings.descSubCat])
            } else {
                this.checkbox[item[this.config.Settings.codeCat]] = [item[this.config.Settings.codeSubCat]]
                this.checkbox2[item[this.config.Settings.descCat]]= [item[this.config.Settings.codeCat],item[this.config.Settings.codeSubCat],item[this.config.Settings.descSubCat]]
            }
        })

     

        let selectedPartyId = this.config.Settings.staffUse ? this.getParam('id') : JSON.parse(document.getElementById("__ClientContext").value).selectedPartyId;
        this.selectId = selectedPartyId;

        
        await this.loadData()

        this.ready = true;
    },
    methods: {
        async loadData(){
            this.isMainList=false
            this.MainList={}
            let checkBoxListss = await mtapi.axios.get(`/api/${this.config.Settings.apiPanel}/${this.selectId}`).catch(function (error) {
            if (error.response) {
                // Request made and server responded
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                
            }
        });

        let isCheckedList= new mtapi.ImisEntity(checkBoxListss?.data)
       
		this.practiceAreaList=isCheckedList[this.config.Settings.apiPanelSubCat]

        let c = [];
        let tempc = [];
        let checktempID = [];
        let checktempID2=[]

        if (checkBoxListss && checkBoxListss.data)
            checkBoxListss.data.Properties.$values.forEach((ele) => {
                if (ele.Name == this.config.Settings.apiPanelCat) {
                    c.push(ele.Value);
                }
                if (ele.Name == this.config.Settings.apiPanelSubCat) {
                    if (ele.Value && ele.Value.split(",")) {
                        tempc = ele.Value.split(",");
                    }
                }
            });
        c.forEach((elements) => {



            elements.split(",").forEach((elementss2) => {

                 for (var key in this.checkbox2) {
                    // console.log(key)
                     if(this.checkbox2[key][0] == elementss2){
                        //  console.log(elementss2)
                            let tempValue=[]
                                tempc.forEach((temp,p) => {
                                    this.checkbox2[key].forEach((item,index)=>{
                                        if((index+2)%3==0){
                                           
                                            if(item == temp){
                                                 
                                                if( this.checkbox2[key][index+1] ){
                                                    checktempID.push(this.checkbox2[key][0] + (index+1)+this.config.SortOrder);
                                                    checktempID2.push(this.checkbox2[key][index+1]+this.config.SortOrder)
                                                    tempValue.push(this.checkbox2[key][index+1])
                                                }
                                            }
                                        }
                                    })
                                });
                                this.MainList[key]=tempValue
                     }
                     
                 }
                
            });
        });

        this.checktempID=checktempID
        this.checktempID2=checktempID2
        // 
        this.isMainList=true

        if(this.config.Settings.displayMode==1){
            setTimeout(() => {
            checktempID.forEach((id) => {
                 if(document.getElementById(id)){
                document.getElementById(id).checked = true;
                 }
            });
        }, 100);

        }

        },

        widthChange(){
            
        },
        show(){
            this.$modal.show('practiceAreasDialog'+this.config.SortOrder)  
            
            setTimeout(() => {
            this.checktempID.forEach((id,n) => {
            
               if(document.getElementById(id)){
                        document.getElementById(id).checked = true;
               }
               
            });
            window.addEventListener("resize", this.myEventHandler);
        }, 500);

        },

        myEventHandler(){
           
            this.checktempID.forEach((id) => {
                if(document.getElementById(id)){
                    document.getElementById(id).checked = true;
                }
                
            });
        },
        getParam(id) {
            let ids = ''
            if (new URLSearchParams(window.location.search).get('id')) {
                id = new URLSearchParams(window.location.search).get('id')
            } else {
                id = new URLSearchParams(window.location.search).get('ID')
            }
            return id ? id : JSON.parse(document.getElementById("__ClientContext").value).selectedPartyId;
        },
        replaceInfo(data) {
            if (data)
                return data.replace("[[max]]", this.config.Settings.maxNum);
        },
        append() {
            for (let i = 0; i < 20; i++) {
                this.items.push({
                    title: `Item ${this.items.length}`,
                    content: "Content",
                });
            }
        },

        async reloadpage() {
           
            this.$modal.hide('practiceAreasDialog'+this.config.SortOrder)
        },
        async saveData() {
            clearInterval(this.varMyTimeout)

            // this.config.Settings.maxCat=6
            // this.config.Settings.maxNumCat=0
            // this.config.Settings.maxNum=3

            this.maxCategory = 0
            this.maxSubCat = 0
             this.maxCounter = 0
             this.maxCounter2=0
            this.isFlag = false
            let element = document.getElementById('parentId'+this.config.SortOrder).children[0].children
            Array.from(element).forEach((ele, i) => {
                
                Array.from(ele.children).forEach((subele, j) => {
                if(this.config.Settings.maxNum>0){
                                         this.maxCounter=0
                                    }
                    if (j < ele.children.length - 1) {
                       let first = true;
                        Array.from(subele.children).forEach((superSubele, k) => {
                            if (k != 0) {
                                if (superSubele.children[0] && superSubele.children[0].children[0].children[0].children[0].checked) {
                                    if(this.config.Settings.maxNum>0){
                                         this.maxCounter++
                                         if(this.maxCounter> this.config.Settings.maxNum){
                                            this.maxCounter2=this.maxCounter
                                         }
                                    }else{
                                        this.maxCounter++
                                    } 
                                    
                                    this.maxSubCat++
                                    if (first) {
                                        this.maxCategory++
                                    }
                                    first = false
                                }
                            }
                        })

                      

                    }
                })
                // if()
            })

            
            
            let isTrue = this.maxCount3()
            if (isTrue  ) {
                let keyValue = "";
                let subCat = "";
                for (var key in this.checkbox2) {
                    if (this.checkbox2.hasOwnProperty(key)) {
                        let isKey = true;
                        this.checkbox2[key].forEach((item, k) => {
                            if (document.getElementById(this.checkbox2[key][0] + k+this.config.SortOrder) &&document.getElementById(this.checkbox2[key][0] + k+this.config.SortOrder).checked) {
                            //    
                                this.checkbox2[key][k-1];
                                subCat = subCat
                                    ? subCat + this.checkbox2[key][k-1] + ","
                                    : this.checkbox2[key][k-1] + ",";
                                if (isKey) {
                                    keyValue = keyValue ? keyValue + this.checkbox2[key][0] + "," : this.checkbox2[key][0] + ",";
                                    isKey = false;
                                }
                            }
                        });
                    }
                }
                
                let json = {
                    $type: "Asi.Soa.Core.DataContracts.GenericEntityData, Asi.Contracts",
                    EntityTypeName: "${this.config.Settings.apiPanel}",
                    Properties: {
                        $type:
                            "Asi.Soa.Core.DataContracts.GenericPropertyDataCollection, Asi.Contracts",
                        $values: [
                            {
                                $type:
                                    "Asi.Soa.Core.DataContracts.GenericPropertyData, Asi.Contracts",
                                Name: "ID",
                                Value: this.selectId,
                            },
                            {
                                $type:
                                    "Asi.Soa.Core.DataContracts.GenericPropertyData, Asi.Contracts",
                                Name: this.config.Settings.apiPanelSubCat,
                                Value: subCat.substring(0, subCat.length - 1),
                            },
                            {
                                $type:
                                    "Asi.Soa.Core.DataContracts.GenericPropertyData, Asi.Contracts",
                                Name: this.config.Settings.apiPanelCat,
                                Value: keyValue.substring(0, keyValue.length - 1),
                            },
                        ],
                    },
                };

               let saveResp= await this.saveAPI(json,subCat.substring(0, subCat.length - 1));
               console.log("Data save successfull")
               this.reloadpage()
               this.loadData()
            }


        },
        async saveAPI(data,subCatValue) {
            try {
                this.responseData = await mtapi.axios.get(
                    `/api/${this.config.Settings.apiPanel}/${this.selectId}`
                );
                if (this.responseData.status == 200) {
                    let updated = await mtapi.axios.put(
                        `/api/${this.config.Settings.apiPanel}/${this.selectId}`,
                        data
                    );
                    if (updated.status == 201 || updated.status == 200) {
                        this.loadData()
                        this.isSuccess = true;
                        setTimeout(() => {
                            this.isSuccess = false;
                           
                            this.$modal.hide('practiceAreasDialog'+this.config.SortOrder)
                           
                        }, 3000);
                        return true
                    }else{
                        return false
                    }
                }
            } catch (error) {
                let updated = await mtapi.axios.post(
                    `/api/${this.config.Settings.apiPanel}`,
                    data
                );
                if (updated.status == 201) {
                    this.isSuccess = true;
                    setTimeout(() => {
                        this.isSuccess = false;
                    }, 5000);
                }
            }
        },

        maxCount(event) {

            clearTimeout(this.varMyTimeout);
            this.isFlag = false
            this.isSuccessToast = false
            this.maxCategory = 0
            this.maxSubCat = 0
            let element = document.getElementById('parentId'+this.config.SortOrder).children[0].children

            Array.from(element).forEach((ele, i) => {
                Array.from(ele.children).forEach((subele, j) => {
                    if (j < ele.children.length - 1) {
                        this.maxCounter = 0
                        let first = true;
                        Array.from(subele.children).forEach((superSubele, k) => {
                            if (k != 0) {
                                if (superSubele.children[0].children[0].children[0].checked) {
                                    this.maxCounter++
                                    this.maxSubCat++
                                   
                                    if (first) {
                                    //    
                                       
                                            this.maxCategory++
                                       
                                        //  

                                    }
                                    first = false
                                }
                            }
                        })
                        
                    }
                })
            })
            if (event.target.checked) {
                
                if (this.config.Settings.maxNum > 0) {
                    let check = event.target.parentElement.parentElement.parentElement.parentElement.children
                    this.maxCounter = 0;
                    //  
                    Array.from(check).forEach((ele, i) => {
                        // 
                        if (i != 0) {
                            // 
                            if (ele.children[0].children[0].children[0].checked) {
                                // 
                                this.maxCounter++
                            }
                        }
                    })
                } else {

                }




                if ((this.config.Settings.maxNum > 0 ? this.maxCounter > this.config.Settings.maxNum : false) || this.maxCategory > this.config.Settings.maxCat || (this.config.Settings.maxNumCat > 0 ? this.maxSubCat > this.config.Settings.maxNumCat : false)) {





                    if (this.config.Settings.maxCat > 0) {
                        event.preventDefault();
                        setTimeout(() => {
                            this.afterCheckCount()
                            
                            if ((this.maxCounter > this.config.Settings.maxNum) || this.config.Settings.maxNum >0) {
                                
                               this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' out  of ' + this.config.Settings.maxCat + ' have been selected '
                                this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxCounter - 1) + ' out of ' + this.config.Settings.maxNum + ' have been selected'
                            } else if (this.maxCategory > this.config.Settings.maxCat) {
                                
                                this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory -1 ) + ' out   of ' + this.config.Settings.maxCat + ' have been selected '
                                this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat - 1) + ' out of ' + this.config.Settings.maxNumCat + ' have been selected'
                            } else if (this.maxSubCat > this.config.Settings.maxNumCat) {
                                 
                                this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory ) + ' out   of  ' + this.config.Settings.maxCat + ' have been selected '
                                this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat ) + ' out   of  ' + this.config.Settings.maxNumCat + ' have been selected '
                                // this.toastMessage2 = 'You have already selected ' + (this.maxCounter - 1) + ' sub category out of ' + this.config.Settings.maxNum
                            }
                             
                            this.isFlag = true
                            
                            
                            this.varMyTimeout = setTimeout(() => {
                                this.isFlag = false
                            }, 10000);
                        }, 100);
                        

                    } else {

                        this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' have been selected '
                        
                        
                        if (this.config.Settings.maxNumCat == 0 && this.config.Settings.maxNum == 0) {
                            this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxCounter) + 'have been selected'
                            this.isSuccessToast = true
                            this.varMyTimeout = setTimeout(() => {
                                this.isSuccessToast = false
                            }, 10000);

                        } else {
                            if (this.config.Settings.maxNumCat > 0) {

                                if (this.maxSubCat > this.config.Settings.maxNumCat) {
                                    
                                    event.preventDefault();

                                    setTimeout(() => {
                                        this.afterCheckCount()
                                        this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' have been selected '
  
                                        this.toastMessage2 = 'You have already selected ' + (this.maxSubCat) + ' sub category out of ' + this.config.Settings.maxNumCat
                                        this.isFlag = true
                                        this.varMyTimeout = setTimeout(() => {
                                            this.isFlag = false
                                        }, 10000);
                                    },200)   
                                } else {
                                    setTimeout(() => {
                                        this.afterCheckCount()
                                    
                                    this.showSuccessMesg(1)
                                    },200)
                                }
                            } else {
                                if(this.config.Settings.maxNum >0){
                                    
                                    if (this.maxSubCat > this.config.Settings.maxNum) {
                                        this.toastMessage2 = 'You have already selected ' + (this.maxCounter - 1) + ' sub category out of ' + this.config.Settings.maxNum
                                        this.isFlag = true
                                        event.preventDefault();
                                        this.varMyTimeout = setTimeout(() => {
                                            this.isFlag = false
                                        }, 10000);
                                    } else {
                                        
                                        this.showSuccessMesg(2)
                                    }

                                }else{
                                    if (this.maxSubCat > this.config.Settings.maxNum) {
                                        this.toastMessage2 = 'You have already selected ' + (this.maxCounter - 1) + ' sub category out of ' + this.config.Settings.maxNum
                                        this.isFlag = true
                                        event.preventDefault();
                                        this.varMyTimeout = setTimeout(() => {
                                            this.isFlag = false
                                        }, 10000);
                                    } else {
                                        
                                        this.showSuccessMesg(2)
                                    }
                                }
                                

                            }
                        }



                    }
                } else {
 
                    this.showSuccessMesg(3)
                }



            }
        },


        maxCount2(event){
            clearTimeout(this.varMyTimeout);
            this.isFlag = false
            this.isSuccessToast = false
            // this.config.Settings.maxCat=6
            // this.config.Settings.maxNumCat=0
            // this.config.Settings.maxNum=3

            this.firstCheck(event)

            if(event.target.checked){
                if(this.config.Settings.maxCat == 0 && this.config.Settings.maxNumCat == 0 && this.config.Settings.maxNum==0){
                   
                    this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) +  ' have been selected'
                    this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) +  ' have been selected'
                    this.isSuccessToast = true
                    this.varMyTimeout = setTimeout(() => {
                        this.isSuccessToast = false
                    }, 10000);
                }else if(this.config.Settings.maxCat == 0 && this.config.Settings.maxNumCat == 0 && this.config.Settings.maxNum>0){
                    this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) +  ' have been selected'
                    if(this.maxCounter > this.config.Settings.maxNum){
                        event.preventDefault()
                        this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxCounter-1) + ' out of ' + this.config.Settings.maxNum + ' have been selected'
                        this.isFlag = true
                        this.varMyTimeout = setTimeout(() => {
                            this.isFlag = false
                        }, 10000);
                    }else{
                        this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxCounter) + ' out of ' + this.config.Settings.maxNum + ' have been selected'
                        this.isSuccessToast = true
                        this.varMyTimeout = setTimeout(() => {
                            this.isSuccessToast = false
                        }, 10000);

                    }
                }else if(this.config.Settings.maxCat == 0 && this.config.Settings.maxNumCat > 0 && this.config.Settings.maxNum==0){
                    
                   
                    if(this.maxSubCat > this.config.Settings.maxNumCat){
                        event.preventDefault()
                        setTimeout(() => {
                            this.afterCheckCount()
                            this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) +  ' have been selected'
                            this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) + ' out of ' + this.config.Settings.maxNumCat + ' have been selected'
                            this.isFlag = true
                            this.varMyTimeout = setTimeout(() => {
                                this.isFlag = false
                            }, 10000);
                        },200)    
                         
                    }else{
                         this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) +  ' have been selected'
                        this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) + ' out of ' + this.config.Settings.maxNumCat + ' have been selected'
                        this.isSuccessToast = true
                        this.varMyTimeout = setTimeout(() => {
                            this.isSuccessToast = false
                        }, 10000);

                    }
                }else if(this.config.Settings.maxCat >0  && this.config.Settings.maxNumCat == 0 && this.config.Settings.maxNum==0){
                    

                    if(this.maxCategory > this.config.Settings.maxCat){
                        event.preventDefault()
                        setTimeout(() => {
                            this.afterCheckCount(event)
                            this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' out of ' + this.config.Settings.maxCat + ' have been selected'
                            this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) +  ' have been selected'
                            this.isFlag = true
                            this.varMyTimeout = setTimeout(() => {
                                this.isFlag = false
                            }, 10000);
                        },200)    
                         
                    }else{
                         this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory)+ ' out of ' + this.config.Settings.maxCat  +  ' have been selected'
                        this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) + ' have been selected'
                        this.isSuccessToast = true
                        this.varMyTimeout = setTimeout(() => {
                            this.isSuccessToast = false
                        }, 10000);

                    }
                }else if(this.config.Settings.maxCat > 0 && this.config.Settings.maxNumCat == 0 && this.config.Settings.maxNum>0){
                    

                    if(this.maxCategory > this.config.Settings.maxCat || this.maxCounter >this.config.Settings.maxNum){
                        event.preventDefault()
                        setTimeout(() => {
                            this.afterCheckCount(event)
                            this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' out of ' + this.config.Settings.maxCat + ' have been selected'
                            this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxCounter) + ' out of ' + this.config.Settings.maxNum  +  ' have been selected'
                            this.isFlag = true
                            this.varMyTimeout = setTimeout(() => {
                                this.isFlag = false
                            }, 10000);
                        },200)    
                         
                    }else{
                         this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory)+ ' out of ' + this.config.Settings.maxCat  +  ' have been selected'
                        this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxCounter) + ' out of ' + this.config.Settings.maxNum  +  ' have been selected'
                        this.isSuccessToast = true
                        this.varMyTimeout = setTimeout(() => {
                            this.isSuccessToast = false
                        }, 10000);

                    }
                }else if(this.config.Settings.maxCat > 0 && this.config.Settings.maxNumCat > 0 && this.config.Settings.maxNum==0){
                    

                    if(this.maxCategory > this.config.Settings.maxCat  || this.maxSubCat >this.config.Settings.maxNumCat){
                        event.preventDefault()
                        setTimeout(() => {
                            this.afterCheckCount(event)
                            this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' out of ' + this.config.Settings.maxCat + ' have been selected'
                            this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) + ' out of ' + this.config.Settings.maxNumCat +  ' have been selected'
                            this.isFlag = true
                            this.varMyTimeout = setTimeout(() => {
                                this.isFlag = false
                            }, 10000);
                        },200)    
                         
                    }else{
                         this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory)+ ' out of ' + this.config.Settings.maxCat  +  ' have been selected'
                        this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat)+ ' out of ' + this.config.Settings.maxNumCat + ' have been selected'
                        this.isSuccessToast = true
                        this.varMyTimeout = setTimeout(() => {
                            this.isSuccessToast = false
                        }, 10000);

                    }
                }
            }


                
        },

        maxCount3(){
            clearTimeout(this.varMyTimeout);
            this.isFlag = false
            this.isSuccessToast = false
            let count=0
             if(this.config.Settings.maxCat == 0 && this.config.Settings.maxNumCat == 0 && this.config.Settings.maxNum==0){
               
                
            }else if(this.config.Settings.maxCat == 0 && this.config.Settings.maxNumCat == 0 && this.config.Settings.maxNum>0){
                
                if(this.maxCounter > this.config.Settings.maxNum){
                    this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) +  ' have been selected'
                    this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxCounter-1) + ' out of ' + this.config.Settings.maxNum + ' have been selected'
                    this.isFlag = true
                    count++
                    this.varMyTimeout = setTimeout(() => {
                        this.isFlag = false
                    }, 10000);
                }
            }else if(this.config.Settings.maxCat == 0 && this.config.Settings.maxNumCat > 0 && this.config.Settings.maxNum==0){
                
                
                if(this.maxSubCat > this.config.Settings.maxNumCat){
                        this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) +  ' have been selected'
                        this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) + ' out of ' + this.config.Settings.maxNumCat + ' have been selected'
                        this.isFlag = true
                        count++
                        this.varMyTimeout = setTimeout(() => {
                            this.isFlag = false
                        }, 10000);
                        
                        
                }
            }else if(this.config.Settings.maxCat >0  && this.config.Settings.maxNumCat == 0 && this.config.Settings.maxNum==0){
                

                if(this.maxCategory > this.config.Settings.maxCat){
                        this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' out of ' + this.config.Settings.maxCat + ' have been selected'
                        this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) +  ' have been selected'
                        this.isFlag = true
                        count++
                        this.varMyTimeout = setTimeout(() => {
                            this.isFlag = false
                        }, 10000);
                       
                        
                }
            }else if(this.config.Settings.maxCat > 0 && this.config.Settings.maxNumCat == 0 && this.config.Settings.maxNum>0){
                

                if(this.maxCategory > this.config.Settings.maxCat || this.maxCounter2 >this.config.Settings.maxNum){
                        this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' out of ' + this.config.Settings.maxCat + ' have been selected'
                        this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxCounter2) + ' out of ' + this.config.Settings.maxNum  +  ' have been selected'
                        this.isFlag = true
                        count++
                        this.varMyTimeout = setTimeout(() => {
                            this.isFlag = false
                        }, 10000);
                       
                        
                }
            }else if(this.config.Settings.maxCat > 0 && this.config.Settings.maxNumCat > 0 && this.config.Settings.maxNum==0){
                

                if(this.maxCategory > this.config.Settings.maxCat  || this.maxSubCat >this.config.Settings.maxNumCat){
                        this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' out of ' + this.config.Settings.maxCat + ' have been selected'
                        this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) + ' out of ' + this.config.Settings.maxNumCat +  ' have been selected'
                        this.isFlag = true
                        count++
                        this.varMyTimeout = setTimeout(() => {
                            this.isFlag = false
                        }, 10000);
                       
                        
                }
            }
            if(count>0){
                return false
            }else{
                return true
            }
        },

        firstCheck(event){
            clearTimeout(this.varMyTimeout);
            this.isFlag = false
            this.isSuccessToast = false
            this.maxCategory = 0
            this.maxSubCat = 0
            let element = document.getElementById('parentId'+this.config.SortOrder).children[0].children

            Array.from(element).forEach((ele, i) => {
                Array.from(ele.children).forEach((subele, j) => {
                    if (j < ele.children.length - 1) {
                        this.maxCounter = 0
                        let first = true;
                        Array.from(subele.children).forEach((superSubele, k) => {
                           
                            if (k != 0) {
                                
                                if ( superSubele.children[0] &&  superSubele.children[0].children[0].children[0].children[0].checked) {
                                this.maxCounter++
                                    this.maxSubCat++
                                   
                                    if (first) {
                                        this.maxCategory++
                                    }
                                    first = false
                                }
                            }
                        })
                        
                    }
                })
            })
            if (event.target.checked) {
                
                if (this.config.Settings.maxNum > 0) {
                    let check = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.children
                    this.maxCounter = 0;
                    Array.from(check).forEach((ele, i) => {
                       if(i!=0){
                            if (ele.children[0] && ele.children[0].children[0].children[0].children[0].checked) {
                                // 
                                this.maxCounter++
                            }
                       }
                        
                    })
                } else {

                }
            }


        },

        afterCheckCount(event){
            let maxCategory = 0
            let maxSubCat = 0
            let maxCounter=0
            let element = document.getElementById('parentId'+this.config.SortOrder).children[0].children

            Array.from(element).forEach((ele, i) => {
                Array.from(ele.children).forEach((subele, j) => {
                    if (j < ele.children.length - 1) {
                        // this.maxCounter = 0
                        let first = true;
                        Array.from(subele.children).forEach((superSubele, k) => {
                            if (k != 0) {
                                
                                if (superSubele.children[0] && superSubele.children[0].children[0].children[0].children[0].checked) {
                                   
                                    // this.maxCounter++
                                    maxSubCat++
                                   
                                    if (first) {
                                    //    
                                       
                                           maxCategory++
                                        
                                        //  

                                    }
                                    first = false
                                }
                            }
                        })
                        
                    }
                })
            })
            
            
            // if (event.target.checked) {
                
                if (this.config.Settings.maxNum > 0) {
                    let check = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.children
                    this.maxCounter = 0;
                    Array.from(check).forEach((ele, i) => {
                        if (i != 0) {
                            if (ele.children[0]&& ele.children[0].children[0].children[0].children[0].checked) {
                                maxCounter++
                            }
                        }
                    })
                } else {

                }
            // }
            
            this.maxCategory=maxCategory
            this.maxCounter=maxCounter
            this.maxSubCat=maxSubCat
           
        },


        showSuccessMesg(i) {
            if(i==3){
                if (this.config.Settings.maxNumCat > 0) {
               
                    this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' out  of ' + this.config.Settings.maxCat + ' have been selected'
                    this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) + ' out of ' + this.config.Settings.maxNumCat + ' have been selected'
                } else {
                   
                    this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' out  of ' + this.config.Settings.maxCat + ' have been selected  \n\ '
                    this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxCounter) + ' out of ' + this.config.Settings.maxNum + ' have been selected'
                }
            }else if(i ==2){
                this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' have been selected '
this.toastMessage2 = 'You have already selected ' + (this.maxCounter - 1) + ' sub category out of ' + this.config.Settings.maxNum

            }else if(i == 1){
 this.toastMessage = this.config.Settings.lblCategory + ' : ' + (this.maxCategory) + ' have been selected '
 this.toastMessage2 =  this.toastMessage2 = this.config.Settings.lblSubCategory + ' : ' + (this.maxSubCat) + ' out of ' + this.config.Settings.maxNumCat + ' have been selected'
            }
            
            this.isSuccessToast = true
            this.varMyTimeout = setTimeout(() => {
                this.isSuccessToast = false
            }, 10000);
        }



    },

};
</script>
<style lang="scss">
.mainList{
    display: block;
}
    .categorytags{ display: flex; align-items: center; gap: 10px; margin: 15px 0; padding: 0; list-style: none; align-items: stretch; flex-wrap: wrap;
        li{ display: block; padding: 8px 10px; font-size: 12px; line-height: 12px; background-color: #f4f4f4; border-radius: 3px; border: solid 1px #cccccc; }
    }
    .categorytagscomp{ display: flex; align-items: center; gap: 10px; margin: 15px 0; padding: 0; list-style: none; align-items: stretch; flex-wrap: wrap;
        li{ display: block; padding-right: 10px; padding-bottom: 10px;
            .parentCategory{
                display: block;
                font-size: 10px;
                padding: 4px 10px;
                margin-bottom: 12px;
                opacity: 0.8;
                border-bottom: 1px solid #cccccc;
            }
            .subCategory{
                padding: 8px 10px; font-size: 12px; line-height: 12px; background-color: #f4f4f4; border-radius: 3px; border: solid 1px #cccccc; margin-right: 4px;margin-bottom: 4px;
    display: inline-block;
            }
            &.blockrow{
                flex-basis: 100%; padding-bottom: 30px;
                .parentCategory{
                    font-size: 13px;
                    opacity: 1;
                    font-weight: 500;
                }
                .parentCategory + .subCategory{
                    margin-left: 10px;
                }
            }
        }
    }
    .selectList{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 15px;
    }
    .edit_btn {
        background-color: #666666;
        color: #ffffff;
        display: inline-block;
        padding: 5px 15px;
        border-radius: 4px;
        transition: all 0.6s;
    }
    .edit_btn:hover {
        background-color: #00b481;
        color: #ffffff;
        text-decoration: none;
    }
    .masonry-wall{
        padding-top: 30px;
        padding-right: 15px;
    }
    .masonry-item {
        padding: 0 0 15px!important;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;
        transition: 0.6s;
        .checkbox {
            padding: 0 15px;
        }
        .heading{
            padding: 15px;
            background-color: #f4f4f4;
        }
        &:hover{
            transform: scale(1.05);
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.30);
        }
    }
    .modalHeader {
        margin: 0 10px;
        text-align: right;
        margin: -55px 5px 0; font-size: 30px; transform: translate(-2rem,75px);
    }
    .modalFooter{
        // position: relative;
        // top: -40px;
        // z-index: 999999;
        background: white;
        padding: 10px 30px;
    }
    .footer-btn{
        text-align: right;
    }
    .close_btn{
        position: relative;
        top: 1px;
        font-size: 25px!important;
    }

    .modalDialog{
        // overflow-y: scroll;
    }

    .vm--container {
        z-index:30000;
    }

    .modalBody {
        padding:1em;
        font-size:0.8em;
        display: flex;
        flex-direction: column;
        align-content: stretch;
        max-height: calc(100% - 60px);
        padding-bottom: 15rem;
        overflow-y: scroll;
    }

    .contentPane {
        flex: 2;
        overflow: hidden;
        overflow-y: scroll;
        /* display: flex; */
    }  

    .modalBody .TwoColumnList .PanelField {
        clear: none;
        width: 50%;
        padding: 0 1em 0 0;
        min-width: 20em;
    }

    .modalBody .ThreeColumnList span.Label {
        display: none;
    }

    .modalBody .ThreeColumnList .SubItems.CheckBoxList h3 {
        font-size: 1.5em;
        line-height: normal;
        margin-top: 0;
    }
    

    .modalBody .ThreeColumnList {
        margin-bottom: 2em;
    }
    .modalBody .ThreeColumnList .CheckBoxList {
        column-count: 3;
        padding: 0.25em 0;
    }  
    @media only screen and (max-width: 768px) {
        .modalBody .CheckBoxList {
            column-count: 1;      
        }  
    }
</style>


<style scoped lang="scss">
    @import "./assets/vue-bootstrap.scss";
    .app {
        .pseudoCatSubCat{
            .actionBtn{
                margin-top:0!important
            }
        }
        

        
    
        .alert_style{
            position: fixed;
            top: 0;
            right: 0;
            margin: 20px;
            z-index: 999999;
        }

        .toast-header {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.25rem 0.75rem;
        color: #6c757d;
        background-color: rgba(255,255,255,.85);
        background-clip: padding-box;
        border-bottom: 1px solid rgba(0,0,0,.05);
    }
    .toast {
        max-width: 350px;
        overflow: hidden;
        font-size: .875rem;
        background-color: rgba(255,255,255,.85);
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.1);
        border-radius: 0.25rem;
        box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        z-index: 999;
    }
    .toast-body {
        padding: 0.75rem;
    }
        .alert_toast {
        position: fixed;
        top: 20px;
        right: 20px;
    }
    color: inherit;
    .heading {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 18px!important;
        display: block;
    }
    .title-info{
        margin-bottom: 10px;
    }
    .heading2 {
        font-size: 14px;
    }
    .inputTitle {
        font-size: 14px;
        line-height: 18px;
        padding-top: 2px;
    }
    .checkrow {
        display: flex;
    }

    
        .vm--container {
        z-index: 30000;
        }

        //    {
        //     padding: 1em;
        //     font-size: 0.8em;
        //     display: flex;
        //     flex-direction: column;
        //     align-content: stretch;
        //     max-height: 100%;
        //   }

        .contentPane {
        flex: 2;
        overflow: hidden;
        overflow-y: scroll;
        /* display: flex; */
        }

        .TwoColumnList .PanelField {
        clear: none;
        width: 50%;
        padding: 0 1em 0 0;
        min-width: 20em;
        }

        .ThreeColumnList span.Label {
        display: none;
        }

        .ThreeColumnList .SubItems.CheckBoxList h3 {
        font-size: 1.5em;
        line-height: normal;
        margin-top: 0;
        }

        .ThreeColumnList {
        margin-bottom: 2em;
        }
        .ThreeColumnList .CheckBoxList {
        column-count: 3;
        padding: 0.25em 0;
        }
        @media only screen and (max-width: 768px) {
        .CheckBoxList {
            column-count: 1;
        }
        }

    }


</style>